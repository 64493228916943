/**
 * Typography
 */

@font-face {
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "proxima-nova", sans-serif;
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "proxima-nova", sans-serif;
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "proxima-nova", sans-serif;
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: "proxima-nova", sans-serif;
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "proxima-nova", sans-serif;
    font-weight: 700;
    font-style: italic;
}

$font-main: "proxima-nova", Helvetica, sans-serif;

a {
    color:inherit;
}

.title {
    font-weight:700;
    margin-bottom:18px;

    font-size:rem(20px);
    line-height:rem(24px);

    @include media-breakpoint-up(md) {
        font-size:rem(24px);
        line-height:rem(30px);
    }

    @media screen and (min-width:1600px) {
        font-size:rem(26px);
        line-height:rem(32px);
    }
    

    @include media-breakpoint-up(md) {
        //margin-bottom:18px;
        //font-size:rem(32px);
        //line-height:rem(32px);
    }

    &:not(.--in-banner):not(.--in-footer) {
        //font-size:rem(20px);
        //line-height:rem(25px);

        @include media-breakpoint-up(md) {
            //font-size:rem(32px);
            //line-height:rem(32px);
        }
    }

    &.--in-banner,
    &.--in-footer {
        //font-size:rem(24px);
        //line-height:rem(30px);

        @include media-breakpoint-up(md) {
            //font-size:rem(30px);
            //line-height:rem(32px);
        }
    }



    &.--cyan {
        color:$cyan;
    }

    &.--dark-sea-green {
        color:$dark-cyan;
    }
}