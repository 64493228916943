/**
 * Navbar
 */
header {
    height: 180px;
    transition: all .2s;
    position: relative;
    z-index: 9985;

    @include media-breakpoint-up(lg) {
        height: 356px;
    }

    &.--tiny {
        .navbar {
            margin-top:-36px;
            //background:red;

            @include media-breakpoint-up(lg) {
                margin-top:0;
            }

            .tagline {
                opacity: 0;
            }

            .navbar-top {
                margin-bottom: -36px;

                @include media-breakpoint-up(lg) {
                    margin-bottom:0px;
                }
            }

            .navbar-bottom {
                transform: translateY(-90px);
                box-shadow: 0px 3px 6px rgba($black, 0.07);
            }
        }
    }
}

.navbar {
    text-align:center;
    position: fixed;
    left: 0px;
    right: 0px;
    top:0;
    background-color: $white;
    transition: margin .2s;

    @include media-breakpoint-up(lg) {
        height: 268px;
        transition: none;
        right: 60px;
    }
    
    @media screen and (min-width:1600px) {
        right:88px;
    }

    .navbar-top {
        margin-top:54px;

        @include media-breakpoint-up(lg) {
            margin-top:78px;
        }

        .brand {
            display:inline-block;
            width: 282px;

            @include media-breakpoint-up(lg) {
                width: 360px;
            }

            .icon {
                display: inline-block;
                width: 60px;

                @include media-breakpoint-up(lg) {
                    width: 72px;
                }
            }

            .tagline {
                display:block;
                margin-top:12px;
                transition: all .2s;

                svg {
                    fill: $sea-green;
                }
            }
        }
    }

    .navbar-bottom {
       margin-top:66px;
       transition: all .2s;
       display: none;
    
       @include media-breakpoint-up(lg) {
            display:block;
            padding-bottom:42px;
       }

        nav {
            button {
                font-weight:500;
                text-transform: uppercase;
                line-height: 100%;
                color:$cyan;
                transition: all .2s;
                font-size:rem(16px);

                @include media-breakpoint-up(xxl) {
                    font-size:rem(18px);
                }

                &:hover {
                    color:$dark-cyan;
                }

                &:not(:first-child) {
                    margin-left:24px;

                    @include media-breakpoint-up(xxl) {
                        margin-left:54px;
                    }
                }
            }
       }
    }
}
