/**
 * Colors
 */

// Default text color
$color-text: $black !default;

// Theme colors
$color-primary: $black;
$color-secondary: $black;

$light-cyan: #F0FAFA;
$cyan: #40c1c9;
$dark-cyan: #519b9d;
$darker-cyan: #60989B;
$light-sea-green: #e9f8f7;
$sea-green: #36787e;
$dark-sea-green: #2B5D5D;
$darker-sea-green: #1F3C3C;

$white: #ffffff;
$black: #0b262a;
$gray: #c4cccc;


$colors: (
  "default":   $color-text,
  "primary":   $color-primary,
  "secondary": $color-secondary,
  "white":     $color-white,
  "light":     $color-light,
  "dark":      $color-dark,
  "black":     $color-black,
  "success":   $color-success,
  "error":     $color-error
) !default;

// Links
$color-link-color: $cyan !default;
$color-link-color-hover: darken($color-link-color, 20%) !default;
//$color-link-color-hover: color.adjust($color, $lightness: -20%) !default;


