/**
 * Footer
 */

footer {
    background:$sea-green;
    padding-top:42px;
    position:relative;
    z-index: 5;
    clear: both;

    @include media-breakpoint-up(xl) {
        padding-bottom:78px;
        padding-top:78px;
        z-index: 9995;
        display:flex;
        justify-content: center;

        @media screen and (max-height:858px) {
            min-height: 100vh;
            align-items: center;
        }
    }
    
    .footer-container {
        @include media-breakpoint-up(xl) {
            width: 100%;
            max-width: 1100px;
        }

        @include media-breakpoint-up(xxl) {
            max-width: 1240px;
        }
    }

    .container {
        //padding-left:36px;
        //padding-right:36px;
        max-width: none;

        @include media-breakpoint-down(xl) {
            max-width: none;
        }
    
        @include media-breakpoint-up(md) {
            padding-left:0px;
            padding-right:0px;
        }
    }

    .footer-top {
        text-align: center;
        padding-bottom:42px;
        padding-left:36px;
        padding-right:36px;
    
        @include media-breakpoint-up(md) {
            padding-left:0px;
            padding-right:0px;
        }

        @include media-breakpoint-up(xl) {
            padding-bottom:0;
        }

        .title {
            position:relative;

            &:after {
                position:absolute;
                bottom:-24px;
                left:calc(50% - 60px);
                background:$darker-cyan;
                height: 1px;
                width: 120px;
                content:'';
            }
        }

        .brand {
            width: 240px;
            margin-top:54px;
            display: inline-block;

            @include media-breakpoint-up(md) {
                width: 270px;
            }
            
            svg {            
                path {
                    fill:$darker-sea-green;
                }
            }
        }

        .cta-container {
            margin-top:36px;
            
            @include media-breakpoint-up(md) {
                margin-top:54px;
            }

            ul {
              list-style: none;
              padding-left:0px;
              
              li {
                display:block;
                width: 100%;

                @include media-breakpoint-up(md) {
                    display:inline;
                }

                a {
                    display: block;

                    @include media-breakpoint-up(md) {
                        display:inline;
                    }
                }
      
                &:not(:first-child) {
                    margin-top:12px;

                    @include media-breakpoint-up(md) {
                        margin-left:12px;
                        margin-top:0px;
                    }
                }
              }
            }
        }

        .address {
            //color:$dark-cyan;
            color:#87BBBC;
            font-size:rem(20px);
            line-height:rem(32px);
            margin-top:32px;
        }
    }

    .footer-bottom {
        color:$darker-cyan;
        background:$white;
        padding-top:36px;
        padding-bottom:102px;

        @include media-breakpoint-up(xl) {
            background:transparent;
            padding-top:0px;
            padding-bottom:0px;
            margin-top:60px;
        }

        .footer-bottom-container {
            display:flex;
            flex-wrap: wrap;
            align-content: center;
            line-height: rem(24px);
            justify-content: center;

            @include media-breakpoint-only(xl) {
                font-size:rem(16px);
            }

            @include media-breakpoint-up(xl) {
                justify-content: space-between;
                line-height: rem(30px);
                flex-wrap: nowrap;
            }
        }

        a, button {
            transition: all .2s;

            &.sign {
                text-transform: uppercase;
            }

            @media (hover: hover) {
                &:hover {
                    color:$cyan;

                    @include media-breakpoint-up(xl) {
                        color:$light-sea-green;
                    }
                }
            }
        }

        button {
            font-size:100%;
            color: inherit;
        }

        ul {
            list-style: none;
            padding-left:0px;
            text-align: center;
            order:1;

            @include media-breakpoint-up(xl) {
                width: 50%;
                order:2;
            }

            li {
                display:inline;
            }
        }
        
        .left {
            order:2;
            margin-right:6px;

            @include media-breakpoint-up(xl) {
                width: 25%;
                order:1;
                margin-right:0px;
            }

            @include media-breakpoint-only(xl) {
                width: 20%;
            }
        }

        .middle {
            @include media-breakpoint-down(xl) {
                width: 100%;
                margin-bottom:12px;
            }
        }

        .right {
            order:3;

            @include media-breakpoint-up(xl) {
                text-align:right;
                width: 25%;
                
                span {
                    display:none;
                }
            }

            @include media-breakpoint-only(xl) {
                width: 20%;
            }
        }
    }
}