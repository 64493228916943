/**
 * Buttons
*/

.btn {
    display: inline-block;

    &.--rounded {
        border-radius:5px;
        font-weight:700;
        transition: all .2s;

        &:not(.--big) {
            //padding:24px 54px;
            padding:12px 30px;
            font-size:rem(20px);
        }

        &.--big {
            padding:12px 18px;
            font-size:rem(20px);

            @include media-breakpoint-up(md) {
                font-size:rem(22px);
            }
        }

        &.--cyan {
            background:$cyan;
            color:$white;

            &:hover {
                background:#4ED4DD;
            }
        }

        &.--sea-green {
            background:$sea-green;
            color:$white;

            &:hover {
                background:$dark-sea-green;
            }
        }

        &.--light-sea-green {
            background:$light-sea-green;
            color:$dark-sea-green;

            &.--on-white {
                &:hover {
                    background:rgba($light-sea-green, .6);
                }
            }

            &:not(.--on-white) {
                &:hover {
                    background:$white;
                }
            }
        }
    }
}

.accordion-btn {
    text-transform: uppercase;
    font-size:rem(18px);
    line-height: rem(24px);
    color:$sea-green;
    margin-top:24px;
    display:flex;
    align-items: center;

    @include media-breakpoint-up(lg) {
        display:none;
    }

    svg {
        margin-left:5px;
        transition: all .2s;
    }
}

button,
input {
    background:none;
    border:0px;
    padding:0px;
    margin:0px;
    //line-height: 100%;
    cursor: pointer;
}