html {
  font-size:14px;
}

.main-container {
  max-width: 1640px;
  margin:0 auto;
  padding-left:36px;
  padding-right:36px;
}

body {
  font-weight:400;
  font-family: $font-main;

  overflow-y:hidden;
  overflow-x: hidden;

  font-size:rem(18px);
  line-height: rem(30px);

  position: relative;
}

.banner {
  position:relative;
  margin-top:72px;

  @include media-breakpoint-up(lg) {
    margin-top:120px;
  }

  @include media-breakpoint-up(xl) {
    margin-top:144px;
  }

  &:after {
    content:'';
    position:absolute;
    //top: calc(50% - 390px);
    top:-42px;
    left: -242px;
    border-radius:100%;
    background:$gray;
    z-index: 0;
    opacity: 0.12;

    width: 546px;
    height: 546px;

    @include media-breakpoint-up(md) {
      left:auto;
      right: -300px;
      top:-90px;
    }

    @include media-breakpoint-up(lg) {
      height: 660px;
      width: 660px;
      right: -360px;
    }

    @include media-breakpoint-up(xl) {
      height: 780px;
      width: 780px;
      right: -390px;
      top:-60px;
    }
  }

  .row {
    flex-direction: column-reverse;

    @include media-breakpoint-up(md) {
      flex-direction: row;
    }
  }

  .text-container {
    position: relative;
    z-index: 5;

    @include media-breakpoint-between(md, xl) {
      width: 108.33333333%;
    }

    h1 {
        margin-top:54px;
        font-weight:500;
        font-size:rem(30px);
        line-height: rem(35px);
        letter-spacing: -0.012em;
        text-align: center;
        padding-left:30px;
        padding-right:30px;

        @include media-breakpoint-up(md) {
          text-align: left;
          font-size:rem(35px);
          line-height: rem(42px);
          padding-left:0px;
          padding-right:0px;
          margin-top:96px;
          letter-spacing: -0.018em;
        }

        @include media-breakpoint-up(lg) {
          font-size:rem(40px);
          line-height: rem(52px);
          letter-spacing: -0.024em;
        }

        @include media-breakpoint-up(xxl) {
          font-size:rem(50px);
          line-height: rem(60px);
          letter-spacing: -0.036em;
          margin-top:54px;
        }

        @media screen and (min-width:1600px) {
          white-space: nowrap;
        }
    
        strong {
          color:$cyan;
          //display:block;
          font-weight:700;
        }

        br {
          display:none;

          @media screen and (min-width:1600px) {
            display:block;
          }
        }
    }

    .cta-container {
      margin-top:24px;
      display:none;

      @include media-breakpoint-up(lg) {
        display: inline-block;
      }

      ul {
        list-style: none;
        padding-left:0px;
        
        li {
          display:inline;

          &:not(:first-child) {
            margin-left:12px;
          }
        }
      }
    }

    .welcoming {
        margin-top:60px;

        @include media-breakpoint-up(md) {
          width: 90%;
        }

        @include media-breakpoint-up(xl) {
          padding-right: 8.33333333%;
          margin-top:72px;
        }

        p {
          & + p {
            margin-top:12px;
          }
        }
    }
  }

  .img-container {
    border-radius: 100%;
    overflow: hidden;
    position: relative;
    z-index: 5;
    margin-right:-16.66666666%;
    margin-left:16.666666666%;

    @include media-breakpoint-only(sm) {
      width: 70%;
      margin-left:0;
      margin-right:0;
      float:right;
    }

    @include media-breakpoint-up(md) {
      //margin-left:16.66666666%;
      //margin-right:-8.33333333%;
    }

    @include media-breakpoint-up(lg) {
      margin-left:8.33333333%;
      margin-right:-38.99999999%;
    }

    @include media-breakpoint-up(xl) {
      margin-right:-8.33333333%;
    }

    img {
      max-width: 100%;
      display: block;
    }
  }
}

.main-content {
  margin-top:90px;

  @include media-breakpoint-up(lg) {
    margin-top:172px;
  }

  .interlude {
    position: relative;
    z-index: 5;

    &:after {
      content:'';
      position:absolute;
      top:-54px;
      right: -150px;
      border-radius:100%;
      background:$light-sea-green;
      height: 372px;
      width: 372px;
      z-index: 0;
      opacity: 0.4;

      @include media-breakpoint-up(md) {
        display:none;
      }
    }

    h2 {
      text-align:right;
      font-size:rem(28px);
      line-height:rem(34px);
      letter-spacing: -0.012em;
      border-right:1px solid $cyan;
      padding-right:24px;
      font-weight:700;
      position: relative;
      z-index: 5;

      @include media-breakpoint-up(md) {
        font-size:rem(36px);
        line-height:rem(42px);
      }

      @include media-breakpoint-up(lg) {
        font-size:rem(48px);
        line-height:rem(54px);
        border-right:2px solid $cyan;
      }

      @include media-breakpoint-up(xl) {
        letter-spacing: -0.03em;
      }

      @include media-breakpoint-only(xxl) {
        font-size:rem(42px);
        line-height:rem(48px);
      }

      span {
        color:$cyan;
        display:block;
      }
    }
  }

  .cols-container {
    position:relative;
    margin-top:90px;

    @include media-breakpoint-up(lg) {
      margin-top:180px; 
    }

    &:before {
      display:none;

      @include media-breakpoint-up(md) {
        display:block;
        content:'';
        position:absolute;
        //top: calc(50% - 390px);
        top:-240px;
        left: -400px;
        border-radius:100%;
        background:$light-sea-green;
        height: 780px;
        width: 780px;
        z-index: 0;
        opacity: 0.4;
      }
    }

    .col-container {
      position:relative;
      z-index: 5;

      &:not(:first-child) {
        margin-top:48px;

        @include media-breakpoint-up(lg) {
          margin-top:0px;
        }
      }

      &.accordion-open {
        .body-container {
          .infos-container {
            display:block;
          }
        }

        .accordion-btn {
          span {
            &.more {
              display:none;
            }
  
            &.less {
              display:block;
            }
          }

          svg {
            transform:rotate(180deg);
          }
        }
      }

      .body-container {
        @include media-breakpoint-only(lg) {
          margin-left:24px;
          padding-right:30px;
        }

        .intro-container {
          position:relative;

          &:before {
            content:'';
            //height:72%;
            height:87%;
            width: 2px;
            position:absolute;
            left:-18px;
            //top:9px;
            top:6px;
            background:$cyan;

            @include media-breakpoint-up(lg) {
              left:-24px;
            }
          }
        }

        .infos-container {
          display:none;

          @include media-breakpoint-up(lg) {
            display:block;
          }
        }

        p {
          margin-top:12px;

          @include media-breakpoint-up(md) {
            margin-top:18px;
          }

          @include media-breakpoint-up(lg) {
            margin-top:24px;
          }
        }
      }

      .accordion-btn {
        position:relative;

        span {
          transition: all .2s;

          &.less {
            display:none;
          }
        }
      }
    }
  }

  .services-container {
    margin-top:60px;

    @include media-breakpoint-up(xxl) {
      display:flex;
      margin-left:-60px;
      width: 100%;
      margin-top:120px;
    }

    .service-container {
      text-align: center;

      @include media-breakpoint-up(xxl) {
        width: calc(25% - 0px);
        margin-left:12px;
        margin-right:12px;
      }

      @media screen and (min-width:1900px) {
        width: calc(25% - 30px);
        margin-left:30px;
        margin-right:30px;
      }

      &.list-open {
        .service-top {
          .btn-container {
            button {
              span {
                &.more {
                  opacity:0;
                  rotate: 45deg;
                }

                &.less {
                  opacity:1;
                  rotate: 0deg;
                }
              }
            }
          }
        }

        .list {
          opacity: 1;
          margin-bottom:-48px;
          max-height: 600px;
        }
      }

      .icon {
        background:$white;
        height: 160px;
        width: 160px;
        display:flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;
        margin:0 auto;
        position: relative;
        z-index: 10;
      }

      .service-top {
        position:relative;
        z-index: 5;
        background:$light-cyan;
        padding: 80px 30px 30px 30px;
        border-radius: 12px;
        margin:-80px auto 0 auto;
        
        h3 {
          font-size: rem(28px);
          line-height:rem(32px);
          font-weight:700;
          margin-top:24px;
          color:$sea-green;
          
          span {
            display:block;
          }
        }
  
        .infos {
          margin-top:24px;
          font-size:rem(16px);
          line-height: rem(24px);
        }
  
        .btn-container {
          display:flex;
          align-items: center;
          justify-content: center;
          margin:12px auto -52px auto;
          position:relative;
  
          &:after {
            content:'';
            width: 100%;
            background:$white;
            height: 3px;
            position:absolute;
            bottom:19px;
            left:0;
            z-index: 0;
          }
  
          button {
            color:$white;
            width: 42px;
            height: 42px;
            border-radius: 100%;
            background:$sea-green;
            font-size:rem(40px);
            position:relative;
            z-index: 5;
            transition: all .2s;
            line-height: rem(0px);

            @media (hover: hover) {
              &:hover {
                background:$dark-sea-green;
              }
            }
            
            span {
              position: absolute;
              transition: all .2s;
              text-align: center;
              top:auto;
              left:0;
              right:0;
              bottom:auto;

              &.less {
                opacity:0;
                rotate:-45deg;
                margin-top: -3px;
              }
            }
          }
        }
  
      }

      .list {
        transition: max-height .2s, opacity .2s;
        z-index: -1;
        position: relative;
        background:$light-cyan;
        //margin-top:calc(-100% - 120px);
        opacity: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
        margin-top: -30px;
        max-height: 0;
        overflow:hidden;

        ul {
          list-style:none;
          padding: 66px 30px 30px 30px;

          li {
            font-size:rem(17px);
            line-height: rem(20px);
            font-weight:700;

            &:not(:last-child) {
              position:relative;
              padding-bottom:18px;
              margin-bottom:18px;

              &:after {
                background:$sea-green;
                width: 84px;
                height: 1px;
                content:'';
                position:absolute;
                bottom:0;
                left:calc(50% - 42px);
              }
            }
          }
        }
      }
    }

    .swiper-container {
      position:relative;

      .swiper-pagination {
        margin-top:72px;
        display: flex;
        justify-content: center;
  
        @include media-breakpoint-up(xxl) {
          display:none;
        }
  
        span {
          width: 10px;
          height: 10px;
          display: inline-block;
          border-radius: 100%;
  
          &:not(:first-child) {
            margin-left:6px;
          }
  
          &:not(.swiper-pagination-bullet-active) {
            background:$cyan;
          }
  
          &.swiper-pagination-bullet-active {
            background:$dark-sea-green;
          }
        }
      }
  
      .swiper-button-container {
        position:absolute;
        top:240px;
        width: 100%;

        @include media-breakpoint-up(md) {
          display:none;
        }

        .swiper-buttons {          
          .swiper-button {
            position:absolute;
            z-index: 10;

            &.swiper-button-prev {
              left:-18px;
              top:0;
            }
  
            &.swiper-button-next {
              right:-18px;
              top:0;
            }
          }
        }
      }
    }
  }

  .testimonials-container {
    margin-top:90px;
    background:$light-cyan;
    padding-top:60px;
    padding-bottom:60px;
    clear: both;

    @include media-breakpoint-up(lg) {
      padding-top:84px;
      padding-bottom:84px;
    }

    @include media-breakpoint-up(xl) {
      margin-top:210px;
    }

    @include media-breakpoint-up(xxl) {
      margin-top:270px;
    }

    .coled {
      position:relative;
    }

    .flex-container {
      display: initial; //content?
      
      .slogan-container {
        display:flex;
        align-items: center;

        @include media-breakpoint-up(lg) {
          float:left;
          height: 100%;
        }

        .slogan {
          text-align:center;
          font-size:rem(30px);
          line-height:rem(35px);
          letter-spacing: -0.012em;
          font-weight:700;
          flex-shrink: 0;
          width: 100%;
          position:relative;
          
          &:after {
            content:'';
            position:absolute;
            bottom:-18px;
            left: calc(50% - 43px);
            background:$cyan;
            height: 1px;
            width: 86px;

            @include media-breakpoint-up(lg) {
              display:none;
            }
          }

          @include media-breakpoint-up(lg) {
            font-size:rem(36px);
            line-height:rem(42px);
            letter-spacing: -0.024em;
            border-right:2px solid $cyan;
            padding-right:30px;
            margin-right:-32px;
            margin-left:40px;
            margin-right:33px;
            text-align:right;
            width: auto;
          }

          @include media-breakpoint-up(xxl) {
            font-size:rem(42px);
            line-height:rem(48px);
            letter-spacing: -0.03em;
            margin-left:80px;
            margin-right:66px;
          }
    
          span {
            color:$cyan;
            display:block;
          }
        }
      }
    }

    .swiper-container {
      display:flex;
      align-items: center;
      margin-top:80px;

      @include media-breakpoint-up(lg) {
        margin-top:0px;
      }

      .testimonial-container {
        .swiper-slide-container {
          display:flex;
          align-items: center;
          justify-content: center;
          flex-direction: column-reverse;

          @include media-breakpoint-up(xl) {
              justify-content: flex-start;
              flex-direction: row;
              padding-right: 8.33333333%;
          }
  
          .left {
            text-align: center;
            flex-shrink: 0;
            margin-top:30px;

            @include media-breakpoint-up(xl) {
              width:186px;
              margin-top:0px;
            }
  
            .img-container {
              overflow: hidden;
              border-radius:120px;
              width:180px;
              height: 240px;
  
              img {
                display: block;
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }
  
            p {
              &.name {
                font-weight:700;
                font-size:rem(20px);
                line-height: rem(25px);
                margin-top:12px;
              }
              
              &.position {
                font-size:rem(15px);
                line-height: rem(25px);
              }
            }
          }
  
          .right {
            position:relative;
            text-align: center;

            @include media-breakpoint-up(xl) {
              margin-left:33px;
              text-align: left;
            }

            @include media-breakpoint-up(xxl) {
              margin-left:66px;
            }

            &:before {
              content:'';
              position:absolute;
              top:-54px;
              left: 0px;
              width: 96px;
              height: 96px;
              background-image: url('../assets/img/icons/icon-quote.svg');
              background-repeat: no-repeat;
              background-position-x: center;
              background-position-y: center;
              background-size: 100%;
              z-index: 0;

              @include media-breakpoint-only(lg) {
                top:-42px;
              }

              @include media-breakpoint-up(xl) {
                left: -36px;
                top:-66px;
              }
            }
  
            p {
              position: relative;
              z-index: 5;
              font-size:rem(18px);
              line-height: rem(28px);
            }
          }
        }
      }

      .swiper-button {
        cursor: pointer;
        transition: all .2s;
        //background:$white;
        border-radius: 100%;
        display:flex;
        align-items: center;
        justify-content: center;
        position:absolute;
        bottom:174px;

        @include media-breakpoint-up(lg) {
          width: 60px;
          height: 60px;
          position: relative;
          bottom:auto;
        }

        @media (hover: hover) {
          &:hover {
            background:$white;
          }
        }

        &.swiper-button-prev {
          position:absolute;
          left:0px;

          @include media-breakpoint-up(md) {
            left:-24px;
          }

          @include media-breakpoint-only(lg) {
            left:-48px;
          }

          svg {
            @include media-breakpoint-up(md) {
              margin-left:-6px;
            }
          }
        }

        &.swiper-button-next {
          position:absolute;
          right:0px;

          @include media-breakpoint-up(md) {
            right:-24px;
          }

          @include media-breakpoint-only(lg) {
            right:-48px;
          }

          svg {
            @include media-breakpoint-up(md) {
              margin-right:-6px;
            }
          }
        }
      }
    }
  }
}

.main-wrapper {
  @include media-breakpoint-up(xl) {
    margin-right:60px;
  }

  @media screen and (min-width:1600px) {
    margin-right:88px;
  }
}

.sidebar {
  position:fixed;
  bottom:0;
  left:0;
  background:$dark-sea-green;
  overflow: hidden;
  z-index: 9990;
  display: flex;
  width: 100%;

  @include media-breakpoint-up(xl) {
    width: 60px;
    height: 100%;
    top:0;
    left:auto;
    bottom:auto;
    right:0;
    display: block;
  }

  @media screen and (min-width:1600px) {
    width: 88px;
  }
  
  button {
    color:$white;
    text-transform: uppercase;
    font-size:rem(20px);
    font-weight:700;
    line-height: 0px;
    background:$cyan;
    transition: all .2s;
    width: 100%;

    @include media-breakpoint-up(xl) {
      rotate:-90deg;
      margin-top:30px;
      margin-left: -30px;
      width: calc(100% + 60px);
      height: 60px;
      font-size:rem(18px);
    }

    @media screen and (min-width:1600px) {
      height: 88px;
      width: calc(100% + 88px);
      margin-top:44px;
      margin-left: -44px;
      font-size:rem(20px);
    }

    @media (hover: hover) {
      &:hover {
        background:#4ED4DD;
      }
    }
  }

  .icon {
    text-align: center;
    width: 72px;
    height: 72px;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;

    @include media-breakpoint-up(xl) {
      margin-top: 48px;
      width: auto;
      height: auto;
      display: block;
    }

    @media screen and (min-width:1600px) {
      margin-top: 72px;
    }

    a {
      svg {
        display: block;

        @include media-breakpoint-up(xl) {
          display: inline;
        }
        
        rect {
          transition: all .2s;
        }
      }

      @media (hover: hover) {
        &:hover {
          svg {
            rect {
              opacity: 0.3;
              //fill:$darker-sea-green;
            }
          }
        }
      }
    }
  }
}

// POPUP
body {
  &.popup-open {
    .popup-overlay {
      opacity: 0.8;
      z-index: 9995;
      pointer-events:auto;
      cursor: pointer;
    }

    .popup-contact {
      opacity: 1;

      .popup-contact-container {
        pointer-events:auto;
      }
    }
  }
}

.popup-overlay {
  background:$dark-sea-green;
  position: fixed;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  pointer-events: none;
  z-index: 9995;
  transition: all .2s;
}

.popup-contact {
  z-index: 9999;
  position: fixed;
  top:0;
  left: 0;
  right:0;
  bottom: 0;
  opacity: 0;
  pointer-events:none;
  display: flex;
  justify-content: center;
  transition: opacity .2s;
  margin-top: calc(1.5rem*.5);
  margin-bottom: calc(1.5rem*.5);

  @media screen and (min-height:650px) {
    align-items: center;
  }

  @include media-breakpoint-up(md) {
    margin-top:36px;
    margin-bottom:36px;
  }

  .popup-contact-container {
    background:$white;
    border-radius: 12px;
    padding: 42px 36px;
    width: 100%;
    text-align: center;
    margin:0 auto;
    position:relative;
    max-height: 100%;
    overflow: auto;

    @include media-breakpoint-up(xl) {
      width: 72%;
      padding:84px;
    }

    @include media-breakpoint-up(xxl) {
      width: 60%;
    }

    button {
      position:absolute;
      transition: all .2s;
      line-height: 0;
      top:24px;
      right: 24px;

      svg {
        width: 18px;
      }

      @media (hover: hover) {
        &:hover {
          opacity: 0.6;
          rotate:90deg;
        }
      }
    }

    .brand {
      svg {
        width: 240px;
        height: auto;

        @include media-breakpoint-up(md) {
          width: 300px;
        }
      }
    }
    
    .infos {
      margin-top:24px;
      font-size:rem(20px);
      line-height: rem(26px);
      color:$sea-green;

      @include media-breakpoint-up(md) {
        margin-top:36px;
        padding-left:72px;
        padding-right:72px;
      }

      span {
        color:$cyan;
      }
    }

    .cta-container {
      margin-top:42px;

      @include media-breakpoint-up(md) {
        margin-top:60px;
      }

      ul {
        list-style: none;
        padding-left:0px;
        
        li {
          display:block;
          width: 100%;

          @include media-breakpoint-up(md) {
            display:inline;
          }
          
          a {
            width: 100%;
            display: block;

            @include media-breakpoint-up(md) {
              display:inline;
            }
          }

          &:not(:first-child) {
            margin-top:12px;

            @include media-breakpoint-up(md) {
              margin-left:12px;
            }
          }
        }
      }
    }

    .address {
      margin-top:18px;
      color:$sea-green;

      @include media-breakpoint-up(md) {
          margin-top:42px;
      }
    }
  }
}

// APPEAR ANIMATION
.section {
  &.--first {
    opacity: 0;
  }

  &.--fade {
    &:not(.--multi) {
      opacity: 0;
    }

    &.--multi {
      .stagger-item {
        opacity: 0;
      }
    }
  }

  &.--translate {
    &.--multi {
      .stagger-item {
        opacity: 0;
        transform: translateY(30px);
      }
    }
  }
}

// ERROR
.page-error {
  .banner {
    text-align: center;
    margin-top:120px;

    @include media-breakpoint-up(xl) {
      margin-top:150px;
    }

    @include media-breakpoint-up(xxl) {
      margin-top:156px;
    }

    @media screen and (min-width:1900px) {
      margin-top:180px;
    }

    p {
      font-size:rem(48px);
      font-weight:700;
      display: block;

      @include media-breakpoint-up(md) {
        font-size:rem(60px);
      }
    }
  }

  .main-content {
    margin-bottom:120px;
    text-align: center;
    margin-top:30px;

    @include media-breakpoint-up(lg) {
      margin-bottom:240px;
    }

    .btn-container {
      margin: 48px auto 0 auto;
      text-align: center;
    }
  }
}